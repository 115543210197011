import VueI18n from 'vue-i18n'
import { localRead } from '@/libs/util'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
// import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import enLocale from './en'
import zhLocale from './zh'
// import jaLocale from './ja'
import esLocale from './es'
import frLocale from './fr'
import ruLocale from './ru'
import jpLocale from './jp'
import koLocale from './ko'

import Vue from 'vue'
// import jp from './jp'
// import ru from './ru'
// import fr from './fr'

Vue.use(VueI18n)


// 自动根据浏览器系统语言设置语言
const navLang = navigator.language.substring(0, 2)
// console.log(navLang,'navlang');
const localLang = navLang || false
// console.log(localLang,'localLang', localRead('local'));
let lang = localLang || localRead('local') || 'zh'

Vue.config.lang = lang

Vue.locale = () => {}


// 引入本地包
const messages = {
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  es: {
    ...esLocale,
    // ...elementEnLocale
  },
  fr: {
    ...frLocale,
    // ...elementEnLocale
  },
  ru: {
    ...ruLocale,
    // ...elementEnLocale
  },
  jp: {
    ...jpLocale,
    // ...elementEnLocale
  },
  ko: {
    ...koLocale,
    // ...elementEnLocale
  },
  
}

// 创建国际化实例
// console.log(lang,'langggggg');
const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true, // 去除国际化警告
})

const vantLocales = lang => {
  if (lang === 'zh') {
    locale.use(lang, zhLocale)
  } else if (lang === 'en') {
    locale.use(lang, enLocale)
  } else if (lang === 'es') {
    locale.use(lang, esLocale)
  } else if (lang === 'jp') {
    locale.use(lang, jpLocale)
  } else if (lang === 'ko') {
    locale.use(lang, koLocale)
  } else if (lang === 'ru') {
    locale.use(lang, ruLocale)
  } else if (lang === 'fr') {
    locale.use(lang, frLocale)
  } 
}
vantLocales(i18n.locale)

// locale.i18n((key, value) => i18n.t(key, value))
// locale.i18n((key, value) =>console.log(key,value))
export default i18n