export default {
  Manager: {
    BusinessInformation:'Business Information',
    Thenameofthemerchant:'The name of the merchant',
    PenguinOverseasShop:'Penguin Overseas Shop',
    Telegramcustomerservice:'Telegram customer service',
    Whitedeer:'White deer',
    announcement:'announcement',
    DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment:'Due to the risk control of the collection channel, our store only supports USDT payment',
    Selectaproduct:'Select a product',
    Productcategory:'Product category',
    Productname:'Product ltem',
    Selectthecountry:'Select the country',
    Theunitpriceoftheproduct:'The unit price of the product',
    Productinventory:'Product inventory',
    Quantitypurchased:'Quantity purchased',
    Specifythegender:'Specify the gender',
    random:'random',
    man:'man',
    woman:'woman',
    mailbox:'mailbox',
    Thetotalamountis:'The total amount is ',
    Placeyourordernow:'Place your order now',
    Pleaseselectacategory:'Please select a category',
    Pleaseselectaproduct:'Please select a product',
    Pleaseselectacountry:'Please select a country',
    Pleaseenteryouremailaddress:'Please enter your email address',
    isPleaseenteryouremailaddress:'Please enter the correct email address',
    Clicktosearchforcategory:'Click to search for category',
    Clicktosearchforname:'Click to search for ltem',
    Clicktosearchforcountry:'Click to search for country',
Afterpasswordtothisemailaddress:'After placing an order, the system will automatically send the card password to this email address',
  }
}