import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI, { Loading ,Message} from 'element-ui';
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from './lang'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



// if(process.env.VUE_APP_ENV === 'dev') {
  axios.defaults.baseURL = 'https://datapi.site';
//  } else {
  //  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
//  }
 let logs = null
 // 请求拦截
 axios.interceptors.request.use(config => {
   logs = Loading.service({
     fullscreen: true
   })
   return config
 })
 // 响应拦截
 axios.interceptors.response.use(response => {
   logs.close()
   return response.data
 })
 
 Vue.prototype.$http = axios


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
