// module.exports = {
export default {
    Manager: {
        BusinessInformation: 'Información del Comercio',
        Thenameofthemerchant: 'Nombre del Comerciante',
        PenguinOverseasShop: 'Tienda de Pingüino en el Extranjero',
        Telegramcustomerservice: 'Servicio al Cliente de Telegram',
        Whitedeer: 'Ciervo Blanco',
        announcement: 'Anuncio',
        DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment: 'Debido al control de riesgo del canal de cobro, nuestra tienda solo admite pagos con USDT',
        Selectaproduct: 'Seleccionar un Producto',
        Productcategory: 'Categoría del Producto',
        Productname: 'Nombre del Producto',
        Selectthecountry: 'Seleccionar el País',
        Theunitpriceoftheproduct: 'Precio Unitario del Producto',
        Productinventory: 'Inventario del Producto',
        Quantitypurchased: 'Cantidad Comprada',
        Specifythegender: 'Especificar el Género',
        random: 'Aleatorio',
        man: 'Hombre',
        woman: 'Mujer',
        mailbox: 'Correo Electrónico',
        Thetotalamountis: 'El monto total es',
        Placeyourordernow: 'Realizar Pedido Ahora',
        Pleaseselectacategory: 'Por favor seleccione una categoría',
        Pleaseselectaproduct: 'Por favor seleccione un producto',
        Pleaseselectacountry: 'Por favor seleccione un país',
        Pleaseenteryouremailaddress: 'Por favor ingrese su dirección de correo electrónico',
        isPleaseenteryouremailaddress:'Introduzca el buzón correcto',
        Clicktosearchforcategory: 'Haz clic para buscar categoría',
        Clicktosearchforname: 'Haz clic para buscar nombre',
        Clicktosearchforcountry: 'Haz clic para buscar país',
    Afterpasswordtothisemailaddress: 'Después de realizar un pedido, el sistema enviará automáticamente la contraseña de la tarjeta a esta dirección de correo electrónico'
    }
}