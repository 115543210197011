<template>
  <div>
    <div data-v-9ea40744="" class="box">
      <div class="guding">
        <el-dropdown>
          <el-button type="primary" style="height: auto">
            {{ yuyanactive.text
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in navbarlink"
              :key="item.id"
              @click.native="yuyango(item.id, item.link)"
              >{{ item.text }}</el-dropdown-item
            >
            <!-- <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
            <el-dropdown-item>双皮奶</el-dropdown-item>
            <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <img
        data-v-9ea40744=""
        id="banner"
        :src="getSites.banner_url"
      />
      <div data-v-9ea40744="" class="main">
        <p data-v-9ea40744="" class="main-title-blue">
          <span
            data-v-9ea40744=""
            class="el-tag el-tag--large el-tag--dark"
            style="width: 100%; text-align: center"
            ><span data-v-9ea40744="" class="el-tag__content">{{
              $t("Manager.BusinessInformation")
            }}</span></span
          >
        </p>
        <ul class="mainss">
          <li>
            <span class="el-tag el-tag--large el-tag--light"
              ><span class="el-tag__content">{{
                $t("Manager.Thenameofthemerchant")
              }}</span
              ><!--v-if--></span
            >{{getSites.info}}
            <!-- {{ $t("Manager.PenguinOverseasShop") }} -->
          </li>
          <li>
            <span class="el-tag el-tag--large el-tag--light"
              ><span class="el-tag__content">{{
                $t("Manager.Telegramcustomerservice")
              }}</span
              ><!--v-if--></span
            ><a :href="getSites.service_link">
              <!-- {{$t("Manager.Whitedeer")}} -->
              {{getSites.service_name}}
            </a>
          </li>

          <li>
            <span class="el-tag el-tag--large el-tag--light"
              ><span class="el-tag__content">{{
                $t("Manager.announcement")
              }}</span
              ><!--v-if--></span
            >
            {{getSites.notice}}
            <!-- {{
              $t(
                "Manager.DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment"
              )
            }} -->
          </li>
        </ul>
      </div>
      <div data-v-9ea40744="" class="main">
        <p data-v-9ea40744="" class="main-title-green">
          <span
            data-v-9ea40744=""
            class="el-tag el-tag--success el-tag--large el-tag--dark"
            style="width: 100%; text-align: center"
            ><span data-v-9ea40744="" class="el-tag__content">{{
              $t("Manager.Selectaproduct")
            }}</span></span
          >
        </p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width=""
          class="demo-ruleForm"
        >
          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item
              :label="$t('Manager.Productcategory')"
              prop="Productcategory"
            >
              <el-select
                v-model="ruleForm.Productcategory"
                size="small"
                filterable
                @change="changgetProduct"
                :placeholder="$t('Manager.Clicktosearchforcategory')"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </p>

          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item :label="$t('Manager.Productname')" prop="Productname">
              <el-select
                v-model="ruleForm.Productname"
                size="small"
                filterable
                :placeholder="$t('Manager.Clicktosearchforname')"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </p>

          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item
              :label="$t('Manager.Selectthecountry')"
              prop="Selectthecountry"
            >
              <el-select
                v-model="ruleForm.Selectthecountry"
                size="small"
                filterable
                :placeholder="$t('Manager.Clicktosearchforcountry')"
              >
                <el-option
                  v-for="item in options3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </p>
          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item :label="$t('Manager.Theunitpriceoftheproduct')">
              <el-input-number
                v-model="ruleForm.Theunitpriceoftheproduct"
                :disabled="true"
                size="small"
              ></el-input-number>
            </el-form-item>
          </p>
          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item :label="$t('Manager.Productinventory')">
              <el-input-number
                v-model="ruleForm.Productinventory"
                :disabled="true"
                size="small"
              ></el-input-number>
            </el-form-item>
          </p>
          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item
              :label="$t('Manager.Quantitypurchased')"
              prop="Quantitypurchased"
            >
              <el-input-number
                v-model="ruleForm.Quantitypurchased"
                :min="1"
                size="small"
                @input="pricenum"
              ></el-input-number>
            </el-form-item>
          </p>
          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item :label="$t('Manager.Specifythegender')" prop="name">
              <el-radio-group
                v-model="ruleForm.Specifythegender"
                style="display: flex"
              >
                <el-radio
                  style="display: flex; align-items: center"
                  :label="1"
                  >{{ $t("Manager.random") }}</el-radio
                >
                <el-radio
                  style="display: flex; align-items: center"
                  :label="2"
                  >{{ $t("Manager.man") }}</el-radio
                >
                <el-radio
                  style="display: flex; align-items: center"
                  :label="3"
                  >{{ $t("Manager.woman") }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </p>
          <p data-v-9ea40744="" class="main-title-green">
            <el-form-item :label="$t('Manager.mailbox')" prop="mailbox">
              <el-input
                size="small"
                v-model="ruleForm.mailbox"
                :placeholder="$t('Manager.Afterpasswordtothisemailaddress')"
              ></el-input>
            </el-form-item>
          </p>

          <el-alert
            :title="
              $t('Manager.Thetotalamountis') +
              ruleForm.Thetotalamountis +
              ' USDT'
            "
            type="warning"
          >
          </el-alert>
          <el-button
            size="small"
            @click="submitForm('ruleForm')"
            style="width: 100%; margin-top: 15px; height: auto"
            type="success"
            >{{ $t("Manager.Placeyourordernow") }}</el-button
          >
          <!-- <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即创建</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkcategory = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("Manager.Pleaseselectacategory")));
      }else{
        callback();
      }
    };
    var checkEname = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("Manager.Pleaseselectacountry")));
      }else{
        callback();
      }
    };
    var checkcountry = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error(this.$t("Manager.Pleaseenteryouremailaddress"))
        );
      }else{
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error(this.$t("Manager.Pleaseenteryouremailaddress"))
        );
      } else {
        const regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        // console.log(regEmail.test(value));
        if (regEmail.test(value)) {
          callback();
        } else {
          return callback(
            new Error(this.$t("Manager.isPleaseenteryouremailaddress"))
          );
        }
      }
    };
    return {
      ruleForm: {
        Productcategory: "",
        Productname: "",
        Selectthecountry: "",
        Theunitpriceoftheproduct: 0,
        Productinventory: 0,
        Quantitypurchased: 1,
        Specifythegender: 1,
        mailbox: "",
        Thetotalamountis: 0.0,
      },
      language: "",
      yuyanactive: { id: 7, text: "Simplified Chinese", link: "zh" },
      navbarlink: [
        { id: 1, text: "English", link: "en" },
        { id: 2, text: "Spanish", link: "es" },
        { id: 3, text: "French", link: "fr" },
        { id: 4, text: "Russian", link: "ru" },
        { id: 5, text: "Japanese", link: "jp" },
        { id: 6, text: "Korean", link: "ko" },
        // { id: 2, text: 'Turkish', link: '/tr' },
        // { id: 3, text: 'Portuguese (Brazil)', link: '/bp' },
        // { id: 4, text: 'Korean', link: '/ko' },
        // { id: 5, text: 'Arabic', link: '/ar' },
      ],
      options1: [],
      options2: [],
      options3: [],
      getSites:{},
      rules: {
        Productcategory: [
          {
            required: true,
            validator: checkcategory,
            trigger: ["blur", "change"],
          },
        ],
        Productname: [
          {
            required: true,
            validator: checkEname,
            trigger: ["blur", "change"],
          },
        ],
        Selectthecountry: [
          {
            required: true,
            validator: checkcountry,
            trigger: ["blur", "change"],
          },
        ],
        Quantitypurchased: [
        { required: true, },
        ],
        mailbox: [
          // {
          //   required: true,
          //   message: this.$t("Manager.Pleaseenteryouremailaddress"),
          //   trigger: "blur",
          // },
          {
            required: true,
            validator: checkEmail,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    showLangauge() {
      const currentLang = window.sessionStorage.getItem("lang") || "zh";
      switch (currentLang) {
        case "zh":
          this.language = "Simplified Chinese";
          break;
        case "en":
          this.language = "English";
          break;
        case "es":
          this.language = "Spanish";
          break;
        case "fr":
          this.language = "French";
          break;
        case "ru":
          this.language = "Russian";
          break;
        case "jp":
          this.language = "Japanese";
          break;
        case "ko":
          this.language = "Korean";
          break;
        // case 'ja':
        //   this.language = '日本语'
      }
    },
    yuyango(id, lang) {
      // console.log(id, lang, "langidd");
      let obj = this.navbarlink.find((val) => val.id == id);
      this.navbarlink = this.navbarlink.filter((val) => val.id != id);
      this.navbarlink = [...this.navbarlink, this.yuyanactive];
      this.yuyanactive = obj;
      // this.Categories2(lang)
      // this.Products({"num":1,"lang":lang})
      this.$i18n.locale = lang;
      window.sessionStorage.setItem("lang", lang);
      // console.log(window.sessionStorage.getItem("lang"), "0000", this.$i18n);
      this.getCategory()
      this.getSite()
      this.getCountries()
      if(this.ruleForm.Productcategory){
        // console.log(this.ruleForm.Productcategory,'this.ruleForm.Productcategory');
        this.changgetProduct(this.ruleForm.Productcategory)
      }
      // window.location.reload()
      // this.$forceUpdate()
      // console.log(obj, 'obj');
      // console.log(this.navbarlink, 'this.navbarlink');
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          // console.log('https://payment_url/'+ Date.now()+'/'+this.ruleForm.Thetotalamountis);
          window.location.href=this.getSites.payment_url+'/'+ Date.now()+'/'+this.ruleForm.Thetotalamountis
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    async getCountries() {
      let data = await this.$http.get("/getCountry?site=1&lang="+this.$i18n.locale);
      
      this.options3 = data.data;
    },
    async getSite() {
      let data = await this.$http.get("/getSite?siteId=1&lang="+this.$i18n.locale);
      this.getSites = data.data[0];
      document.title=this.getSites.name
    },
    async getCategory() {
      // console.log(this.$i18n.locale,'3333');
      let data = await this.$http.get("/getCategory?site=1&lang="+this.$i18n.locale);
      this.options1 = data.data;
    },
    async changgetProduct(e) {
      console.log(e);
      this.ruleForm.Productname = "";
      this.ruleForm.Theunitpriceoftheproduct = "";
      this.ruleForm.Productinventory = "";
      this.ruleForm.Thetotalamountis = "";
      let data = await this.$http.get(
        "/getProduct?categoryId=" + e
      +'&lang='+this.$i18n.locale);
      this.options2 = data.data;
    },
    pricenum(e) {
      console.log(e);
      this.ruleForm.Thetotalamountis = (
        Math.round(this.ruleForm.Theunitpriceoftheproduct * e * 100) / 100
      ).toFixed(2);
    },
  },
  created() {
    this.getCountries();
    this.getCategory();
    this.getSite()
    this.showLangauge();
  },
  watch: {
    "ruleForm.Productname"(a, b) {
      console.log(a, b);
      //  if(b){
      let obj = this.options2.find((val) => val.id == a);
      let objs = this.options3.find(
        (val) => val.id == this.ruleForm.Selectthecountry
      );

      console.log(obj, objs);
      if (obj && objs) {
        this.ruleForm.Theunitpriceoftheproduct =
          Math.round(obj.price * objs.rate * 100) / 100;
        this.ruleForm.Productinventory = Math.round(obj.stock * objs.rate);
        this.ruleForm.Thetotalamountis =
          Math.round(
            this.ruleForm.Theunitpriceoftheproduct *
              this.ruleForm.Quantitypurchased *
              100
          ) / 100;
      }
      //  }
    },
    "ruleForm.Selectthecountry"(a, b) {
      console.log(a, b);
      // if (b) {
      let obj = this.options3.find((val) => val.id == a);
      let objs = this.options2.find(
        (val) => val.id == this.ruleForm.Productname
      );

      console.log(obj, objs);
      if (objs) {
        this.ruleForm.Theunitpriceoftheproduct =
          Math.round(objs.price * obj.rate * 100) / 100;
        this.ruleForm.Productinventory = Math.round(objs.stock * obj.rate);
        this.ruleForm.Thetotalamountis =
          Math.round(
            this.ruleForm.Theunitpriceoftheproduct *
              this.ruleForm.Quantitypurchased *
              100
          ) / 100;
      }
      // }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/css/index.8ae6bf57.css");
.mainss {
  padding: 5px 0;
  margin: 0;
  list-style: none;
  li {
    padding: 13px;
    font-size: 14px;
    color: #333;
    .el-tag {
      margin-right: 10px !important;
    }
  }
  li:nth-child(2n) {
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
  }
}
</style>
<style>
/* .el-input--suffix .el-input__inner {
  height: 32px !important;
} */
.el-form-item__label {
  display: flex;
  align-items: center;
}
.guding {
  position: fixed;
  right: 10%;
  top: 1%;
}
</style>
