// module.exports = {
export default {
    Manager: {
        BusinessInformation: '사업자 정보',
        Thenameofthemerchant: '상인 이름',
        PenguinOverseasShop: '펭귄 해외 상점',
        Telegramcustomerservice: '텔레그램 고객 서비스',
        Whitedeer: '백사슴',
        announcement: '공지',
        DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment: '수금 채널의 위험 관리로 인해, 저희 상점은 USDT 결제만 지원합니다',
        Selectaproduct: '상품 선택',
        Productcategory: '상품 분류',
        Productname: '상품명',
        Selectthecountry: '국가 선택',
        Theunitpriceoftheproduct: '상품 단가',
        Productinventory: '상품 재고',
        Quantitypurchased: '구매 수량',
        Specifythegender: '성별 지정',
        random: '랜덤',
        man: '남자',
        woman: '여자',
        mailbox: '이메일',
        Thetotalamountis: '총 금액',
        Placeyourordernow: '주문하기',
        Pleaseselectacategory: '분류를 선택하세요',
        Pleaseselectaproduct: '제품을 선택하세요',
        Pleaseselectacountry: '국가를 선택하세요',
        Pleaseenteryouremailaddress: '이메일 주소를 입력하세요',
        isPleaseenteryouremailaddress:'올바른 메일박스를 입력하십시오.',
        Clicktosearchforcategory: '분류 검색을 클릭하세요',
        Clicktosearchforname: '제목 검색을 클릭하세요',
        Clicktosearchforcountry: '국가 검색을 클릭하세요',
    Afterpasswordtothisemailaddress: '주문 후 시스템에서 카드 비밀번호를 이 이메일 주소로 자동으로 전송합니다'

    }
}