// module.exports = {
export default {
    Manager: {
        BusinessInformation: 'マーチャント情報',
        Thenameofthemerchant: 'マーチャント名',
        PenguinOverseasShop: 'ペンギン海外ショップ',
        Telegramcustomerservice: 'テレックスカスタマーサービス',
        Whitedeer: 'ホワイトディア',
        announcement: 'お知らせ',
        DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment: 'コレクションチャンネルのリスク管理により、弊店舗ではUSDT決済のみをサポートしています',
        Selectaproduct: '商品を選択',
        Productcategory: '商品カテゴリー',
        Productname: '商品名',
        Selectthecountry: '国を選択',
        Theunitpriceoftheproduct: '商品単価',
        Productinventory: '商品在庫',
        Quantitypurchased: '購入数量',
        Specifythegender: '性別を指定',
        random: 'ランダム',
        man: '男性',
        woman: '女性',
        mailbox: 'メールボックス',
        Thetotalamountis: '合計金額',
        Placeyourordernow: '今すぐ注文する',
        Pleaseselectacategory: 'カテゴリーを選択してください',
        Pleaseselectaproduct: '商品を選択してください',
        Pleaseselectacountry: '国を選択してください',
        Pleaseenteryouremailaddress: 'メールアドレスを入力してください',
        Clicktosearchforcategory: 'カテゴリーを検索するにはクリックしてください',
        isPleaseenteryouremailaddress:'正しいメールアドレスを入力してください',
        Clicktosearchforname: '名前を検索するにはクリックしてください',
        Clicktosearchforcountry: '国を検索するにはクリックしてください',
    Afterpasswordtothisemailaddress: '注文後、システムが自動的にカードのパスワードをこのメールアドレスに送信します'
        
        
        
        
        
        
        

    }
}