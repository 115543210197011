// module.exports = {
export default {
    Manager: {
        BusinessInformation:'商户信息',
        Thenameofthemerchant:'商户名称',
        PenguinOverseasShop:'企鹅海外小店',
        Telegramcustomerservice:'telegram客服',
        Whitedeer:'白鹿',
        announcement:'公告',
        DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment:'telegram客服',
        Selectaproduct:'选择商品',
        Productcategory:'商品分类',
        Productname:'商品名称',
        Selectthecountry:'选择国家',
        Theunitpriceoftheproduct:'商品单价',
        Productinventory:'商品库存',
        Quantitypurchased:'购买数量',
        Specifythegender:'指定性别',
        random:'随机',
        man:'男',
        woman:'女',
        mailbox:'邮箱',
        Thetotalamountis:'总金额 ',
        Placeyourordernow:'立即下单',
        Pleaseselectacategory:'请选择分类',
        Pleaseselectaproduct:'请选择产品',
        Pleaseselectacountry:'请选择国家',
        Pleaseenteryouremailaddress:'请输入邮箱',
        isPleaseenteryouremailaddress:'请输入正确的邮箱',
        Clicktosearchforcategory:'点击搜索分类',
        Clicktosearchforname:'点击搜索项目',
        Clicktosearchforcountry:'点击搜索国家',
    Afterpasswordtothisemailaddress:'下单后系统自动发送卡密到该邮箱',
      }
}