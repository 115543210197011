// module.exports = {
export default {
    Manager: {
        BusinessInformation: 'Informations sur le commerçant',
        Thenameofthemerchant: 'Nom du commerçant',
        PenguinOverseasShop: 'Boutique Outre-Mer de Penguin',
        Telegramcustomerservice: 'Service clientèle Telegram',
        Whitedeer: 'Cerf Blanc',
        announcement: 'Annonce',
        DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment: `En raison du contrôle des risques du canal de collecte, notre magasin n'accepte que les paiements USDT`,
        Selectaproduct: 'Sélectionner un produit',
        Productcategory: 'Catégorie du produit',
        Productname: 'Nom du produit',
        Selectthecountry: 'Sélectionner le pays',
        Theunitpriceoftheproduct: 'Prix unitaire du produit',
        Productinventory: 'Inventaire du produit',
        Quantitypurchased: 'Quantité achetée',
        Specifythegender: 'Spécifier le genre',
        random: 'Aléatoire',
        man: 'Homme',
        woman: 'Femme',
        mailbox: 'Boîte aux lettres',
        Thetotalamountis: 'Le montant total est',
        Placeyourordernow: 'Passer votre commande maintenant',
        Pleaseselectacategory: 'Veuillez sélectionner une catégorie',
        Pleaseselectaproduct: 'Veuillez sélectionner un produit',
        Pleaseselectacountry: 'Veuillez sélectionner un pays',
        Pleaseenteryouremailaddress: 'Veuillez entrer votre adresse e-mail',
        isPleaseenteryouremailaddress:'Veuillez entrer la bonne boîte aux lettres.',
        Clicktosearchforcategory: 'Cliquez pour rechercher une catégorie',
        Clicktosearchforname: 'Cliquez pour rechercher un nom',
        Clicktosearchforcountry: 'Cliquez pour rechercher un pays',
    Afterpasswordtothisemailaddress: 'Après avoir passé une commande, le système enverra automatiquement le mot de passe de la carte à cette adresse e-mail'

    }
}