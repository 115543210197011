// module.exports = {
export default {
    Manager: {
        BusinessInformation: 'Информация о продавце',
        Thenameofthemerchant: 'Наименование продавца',
        PenguinOverseasShop: 'Магазин "Penguin Overseas"',
        Telegramcustomerservice: 'Обслуживание клиентов через Telegram',
        Whitedeer: 'Белый олень',
        announcement: 'Объявление',
        DuetotheriskcontrolofthecollectionchannelourstoreonlysupportsUSDTpayment: 'В связи с риск-контролем канала сбора, наш магазин принимает только платежи USDT',
        Selectaproduct: 'Выберите товар',
        Productcategory: 'Категория товара',
        Productname: 'Наименование товара',
        Selectthecountry: 'Выберите страну',
        Theunitpriceoftheproduct: 'Цена за единицу товара',
        Productinventory: 'Наличие товара',
        Quantitypurchased: 'Количество покупок',
        Specifythegender: 'Укажите пол',
        random: 'Случайно',
        man: 'Мужчина',
        woman: 'Женщина',
        mailbox: 'Почтовый ящик',
        Thetotalamountis: 'Общая сумма',
        Placeyourordernow: 'Оформить заказ',
        Pleaseselectacategory: 'Выберите категорию',
        Pleaseselectaproduct: 'Выберите продукт',
        Pleaseselectacountry: 'Выберите страну',
        Pleaseenteryouremailaddress: 'Введите адрес электронной почты',
        isPleaseenteryouremailaddress:'Введите правильный почтовый ящик. ',
        Clicktosearchforcategory: 'Нажмите, чтобы найти категорию',
        Clicktosearchforname: 'Нажмите, чтобы найти наименование',
        Clicktosearchforcountry: 'Нажмите, чтобы найти страну',
    Afterpasswordtothisemailaddress: 'После размещения заказа система автоматически отправит пароль от карты на этот адрес электронной почты'

    }
}